@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-300.eot);
    src: local('Roboto Light'), local('Roboto-Light'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-300.eot)
            format('embedded-opentype'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-300.woff2) format('woff2'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-300.woff) format('woff'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-300.ttf) format('truetype'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-300.svg) format('svg');
}
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-italic.eot);
    src: local('Roboto Italic'), local('Roboto-Italic'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-italic.eot)
            format('embedded-opentype'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-italic.woff2) format('woff2'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-italic.woff) format('woff'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-italic.ttf) format('truetype'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-italic.svg) format('svg');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-regular.eot);
    src: local('Roboto'), local('Roboto-Regular'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-regular.eot)
            format('embedded-opentype'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-regular.woff2) format('woff2'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-regular.woff) format('woff'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-regular.ttf) format('truetype'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-regular.svg) format('svg');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-500.eot);
    src: local('Roboto Medium'), local('Roboto-Medium'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-500.eot)
            format('embedded-opentype'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-500.woff2) format('woff2'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-500.woff) format('woff'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-500.ttf) format('truetype'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-500.svg) format('svg');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-700.eot);
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-700.eot)
            format('embedded-opentype'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-700.woff2) format('woff2'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-700.woff) format('woff'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-700.ttf) format('truetype'),
        url(~zeppelin-element-library/assets/fonts/roboto-v18-latin-700.svg) format('svg');
}
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    src: url(~zeppelin-element-library/assets/fonts/roboto-condensed-v18-latin-regular.eot);
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
        url(~zeppelin-element-library/assets/fonts/roboto-condensed-v18-latin-regular.eot)
            format('embedded-opentype'),
        url(~zeppelin-element-library/assets/fonts/roboto-condensed-v18-latin-regular.woff2)
            format('woff2'),
        url(~zeppelin-element-library/assets/fonts/roboto-condensed-v18-latin-regular.woff)
            format('woff'),
        url(~zeppelin-element-library/assets/fonts/roboto-condensed-v18-latin-regular.ttf)
            format('truetype'),
        url(~zeppelin-element-library/assets/fonts/roboto-condensed-v18-latin-regular.svg)
            format('svg');
}
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    src: url(~zeppelin-element-library/assets/fonts/roboto-condensed-v18-latin-700.eot);
    src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'),
        url(~zeppelin-element-library/assets/fonts/roboto-condensed-v18-latin-700.eot)
            format('embedded-opentype'),
        url(~zeppelin-element-library/assets/fonts/roboto-condensed-v18-latin-700.woff2)
            format('woff2'),
        url(~zeppelin-element-library/assets/fonts/roboto-condensed-v18-latin-700.woff)
            format('woff'),
        url(~zeppelin-element-library/assets/fonts/roboto-condensed-v18-latin-700.ttf)
            format('truetype'),
        url(~zeppelin-element-library/assets/fonts/roboto-condensed-v18-latin-700.svg) format('svg');
}

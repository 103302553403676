a {
    text-decoration: none;
    color: inherit !important;
}

html,
body {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.84);
    margin: 0;
}

* {
    box-sizing: border-box;
}
